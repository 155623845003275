<!-- 疫情排查检测管控 by阿旭 -->
<template>
  <div>
    <div v-if="!isShowDetail" class="main">
      <div class="top">
        <div class="top_01">
          <div class="top_011">
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">事件场所名称:</span>
              <el-cascader v-model="regitsterInfo.eventsite" :options="eventNameSelect" :props="sjprops" filterable
                collapse-tags clearable></el-cascader>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">人群分类:</span>
              <el-select style="width: 260px" v-model="regitsterInfo.crowdTypeIds" placeholder="请选择" clearable filterable
                multiple collapse-tags>
                <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"
                  :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">信息来源:</span>
              <el-cascader class="select-width" ref="taskSourceRef" v-model="regitsterInfo.taskSourceCode"
                :props="{ ...dzprops, checkStrictly: true }" @change="handleTaskSource" clearable></el-cascader>
            </div>
          </div>
          <div class="top_01_btns">
            <el-button type="primary" @click="caseList()">查询</el-button>
            <el-button type="primary" @click="synchronousDetection()">同步检测</el-button>
            <el-button type="primary" @click="escalateExport('btn')">导出</el-button>
          </div>
        </div>
        <div class="top_02">
          <div class="top_011">
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">阳性病例:</span>
              <el-select clearable filterable remote :remote-method="relationList"
                v-model="regitsterInfo.positiveNamezdm">
                <el-option v-for="(item, k) in typepopSelect11" :key="k" :label="item.zdm" :value="item.zdm"
                  @click.native="yxecho(item)">
                </el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">密接关联信息:</span>
              <el-select clearable filterable remote :remote-method="mqrelationList"
                v-model="regitsterInfo.closelyNamezdm">
                <el-option v-for="item in typepopSelect11mj" :key="item.id" :label="item.zdm" :value="item.zdm"
                  @click.native="echo(item)"></el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">流调结果:</span>
              <el-select v-model="regitsterInfo.surveyResults" placeholder="请选择" clearable multiple>
                <el-option v-for="item in surveyResults" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">协查函:</span>
              <el-input v-model="regitsterInfo.letterNo" placeholder="请输入内容" clearable></el-input>
            </div>
          </div>
        </div>
        <div class="top_02">
          <div class="top_011">
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">流调员:</span>
              <el-select v-model="regitsterInfo.surveyUserName" class="select-width" clearable filterable>
                <el-option v-for="item in surveyUserNameSelect" :key="item.id" :label="item.name" :value="item.name"
                  @click.native="handleSurveyUserName(item)"></el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">隔离点:</span>
              <el-select v-model="regitsterInfo.isolationPointId" placeholder="请选择" clearable filterable>
                <el-option v-for="item in gldoptions" :key="item.id" :label="item.orgName" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">管控社区:</span>
              <el-select class="top_left_inputnationality select-width" v-model="regitsterInfo.surveyObjectBelongOrg"
                placeholder="请选择" clearable filterable>
                <el-option v-for="item in surveyObjectBelongOrgSelect" :key="item.orgCode" :label="item.orgName"
                  :value="item.orgCode" @click.native="handelSurveyObjectBelongOrg(item)"></el-option>
              </el-select>
            </div>
            <div class="top_01_div">
              <span style="font-size: 14px; color: #606266">流调时间:</span>
              <el-date-picker v-model="ldtime" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-table height="80%" :data="tableData" style="width: 100%; flex: 1" show-summary>
          <el-table-column prop="crowdType" label="人群分类" width="150">
            <template slot-scope="{ row }">
              {{ row.crowdTypeName | crowdType }}
            </template>
          </el-table-column>
          <el-table-column prop="surveyNum" label="排查追踪" width="150">
          </el-table-column>
          <el-table-column prop="excludeNum" label="排除" width="150">
          </el-table-column>
          <el-table-column prop="assistNum" label="他区协查" width="150">
          </el-table-column>
          <el-table-column prop="homeNum" label="居家隔离" width="150">
          </el-table-column>
          <el-table-column prop="isolationNum" label="集中隔离" width="150">
          </el-table-column>
          <el-table-column prop="hospitalNum" label="院内隔离" width="150">
          </el-table-column>
          <el-table-column prop="releaseNum" label="解除隔离" width="150">
          </el-table-column>

          <el-table-column label="第一次核酸采样">
            <el-table-column prop="collectedNum1" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum1" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum1" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column label="第二次核酸采样">
            <el-table-column prop="collectedNum2" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum2" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum2" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column label="第三次核酸采样">
            <el-table-column prop="collectedNum3" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum3" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum3" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column label="第四次核酸采样">
            <el-table-column prop="collectedNum4" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum4" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum4" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column label="第五次核酸采样">
            <el-table-column prop="collectedNum5" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum5" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum5" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column label="第六次核酸采样">
            <el-table-column prop="collectedNum6" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum6" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum6" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
          <el-table-column label="第七次核酸采样">
            <el-table-column prop="collectedNum7" label="已采样" width="120">
            </el-table-column>
            <el-table-column prop="resultNum7" label="已报告" width="120">
            </el-table-column>
            <el-table-column prop="positiveNum7" label="阳性" width="120">
            </el-table-column>
          </el-table-column>
        </el-table>
        <!-- <el-pagination style="margin-top: 20px" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 50, 100, 200]" :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination> -->
      </div>
      <!-- 弹窗 -->
      <el-dialog title="设置" :visible.sync="dialogVisible" width="30%">
        <div class="dialog_top">
          <el-checkbox v-model="checked" @change="handleCheckAllChange">待选属性</el-checkbox>
          <span style="color: #ccc">{{ istableb02.length }}/62项</span>
        </div>
        <div class="sst">
          <el-tree show-checkbox :data="treedata" ref="organizationData" node-key="id" default-expand-all
            @check-change="handleCheckChange">
          </el-tree>
        </div>
        <div class="dialog_bottom">
          <el-button type="primary" size="mini" @click="qd">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script>
import { http } from "@/api/index";
import { mapState } from "vuex";
import { getMedicalOrgList } from "@/api/SystemManagement/index";
import { SURVEY_RESULT, INFO_TYPE } from "@/common/constants";
import {
  getUserListByPeopleTypeApi,
  getEventListApi,
  relationList,
  checkList,
  exportList
} from "@/api/DiseaseControlManagement/DiseaseControlManagement";
import { isolationpointList } from "@/api/SystemManagement/index";
import { pullCovidResult } from "@/api/QuarantineRegistration/quarantineRegistrationpop";
import { importFile, messageBox } from "@/utils/utils";
import constants from "../../common/constants";
import residentInformation from "@/views/QuarantineRegistration/residentInformation";

export default {
  components: {
    residentInformation,
  },
  data() {
    return {
      props: { multiple: true },
      // 场所事件
      sjprops: {
        value: "id",
        label: "eventName",
        lazy: true,
        multiple: true,
        checkStrictly: true,
        lazyLoad(node, resolve) {
          const { level } = node;

          // 获取调查场所
          let params = { eventId: node.value };
          if (level == 1) {
            http({
              method: "get",
              url: `/ohealth/api/v1/epidemicControl/placeinvolved/getPlaceList?eventId=${node.value}`,
              data: params,
            }).then(function (response) {
              let list = response.data.data;

              const nodes = list.map((item) => ({
                id: item.id,
                eventName: item.placeName,
                leaf: level >= 1,
              }));
              resolve(nodes);
            });
          }
        },
      },
      ldtime: [],
      isShowDetail: false,
      checked: false,
      currentPage: 1,
      total: 0,
      pagesize: 10,
      tableData: [],
      options: [],
      value: "",
      istableb: [],
      istableb02: [],
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      regitsterInfo: {},
      typepopSelect11: [],
      typepopSelect11mj: [],
      positiveCaseForm: {},
      gldoptions: [],
      surveyObjectBelongOrgSelect: [], //所属社区
      surveyUserNameSelect: [], //调查人员项
      eventNameSelect: [],
      closeContactForm: {},
      dialogVisible: false,
      ishh: [],
      treedata: [
        {
          label: "姓名",
          id: 0,
          prop: "name",
        },
        {
          label: "性别",
          id: 1,
          prop: "sex",
        },
        { label: "证件类型", id: 2, prop: "cardType" },
        { label: "证件号码", id: 3, prop: "cardNo" },
        { label: "联系电话", id: 4, prop: "linkPhone" },
        { label: "年龄", id: 5, prop: "age" },
        { label: "国籍", id: 6, prop: "country" },
        { label: "现住地", id: 7, prop: "currentAddress" },
        {
          label: "民族",
          id: 8,
          prop: "nation",
        },
        { label: "人群分类", id: 9, prop: "crowdType" },
        { label: "事件名称", id: 10, prop: "eventName" },
        { label: "调查场所", id: 11, prop: "placeName" },
        { label: "流调结果", id: 12, prop: "surveyResults" },
        { label: "负责社区", id: 13, prop: "surveyObjectBelongOrg" },
        { label: "隔离地点", id: 14, prop: "isolationName" },
        { label: "开始医学观察日期", id: 15, prop: "startTime" },
        { label: "解除医学观察隔离日期", id: 16, prop: "endTime" },
        { label: "可能性最大关联者姓名", id: 17, prop: "relationName" },
        { label: "可能性最大关联者证件号", id: 18, prop: "relationCardNo" },
        { label: "最早接触时间", id: 19, prop: "firstContactTime" },
        { label: "最后接触时间", id: 20, prop: "lastContactTime" },
        { label: "接触地点", id: 21, prop: "contactAddress" },
        { label: "接触方式", id: 22, prop: "contactType" },
        { label: "单次暴露时间（分钟）", id: 23, prop: "singleExposureTime" },
        { label: "流调员", id: 24, prop: "surveyUserName" },
        { label: "流调登记时间", id: 25, prop: "crtTime" },
        { label: "任务来源", id: 26, prop: "taskSource" },
        { label: "信息类别", id: 27, prop: "infoType" },
        {
          label: "第1次核酸",
          id: 28,
          prop: "collectTime1",

          children: [
            {
              label: "采样时间",
              id: 29,
              prop: "collectTime1",
            },
            {
              label: "采样单位",
              id: 30,
              prop: "covidCollectPoint1",
            },
            {
              label: "核酸结果",
              id: 31,
              prop: "labResult1",
            },
            {
              label: "检测单位",
              id: 32,
              prop: "labOrg1",
            },
            {
              label: "检测日期",
              id: 33,
              prop: "reportTime1",
            },
          ],
        },
        {
          label: "第2次核酸",
          id: 34,
          prop: "collectTime2",

          children: [
            {
              label: "采样时间",
              id: 35,
              prop: "collectTime2",
            },
            {
              label: "采样单位",
              id: 36,
              prop: "covidCollectPoint2",
            },
            {
              label: "核酸结果",
              id: 37,
              prop: "labResult2",
            },
            {
              label: "检测单位",
              id: 38,
              prop: "labOrg2",
            },
            {
              label: "检测日期",
              id: 39,
              prop: "reportTime2",
            },
          ],
        },
        {
          label: "第3次核酸",
          id: 40,
          prop: "collectTime3",

          children: [
            {
              label: "采样时间",
              id: 41,
              prop: "collectTime3",
            },
            {
              label: "采样单位",
              id: 42,
              prop: "covidCollectPoint3",
            },
            {
              label: "核酸结果",
              id: 43,
              prop: "labResult3",
            },
            {
              label: "检测单位",
              id: 44,
              prop: "labOrg3",
            },
            {
              label: "检测日期",
              id: 45,
              prop: "reportTime3",
            },
          ],
        },
        {
          label: "第4次核酸",
          id: 46,
          prop: "collectTime4",

          children: [
            {
              label: "采样时间",
              id: 47,
              prop: "collectTime4",
            },
            {
              label: "采样单位",
              id: 48,
              prop: "covidCollectPoint4",
            },
            {
              label: "核酸结果",
              id: 49,
              prop: "labResult4",
            },
            {
              label: "检测单位",
              id: 50,
              prop: "labOrg4",
            },
            {
              label: "检测日期",
              id: 51,
              prop: "reportTime4",
            },
          ],
        },
        {
          label: "第5次核酸",
          id: 52,
          prop: "collectTime5",

          children: [
            {
              id: 533,
              label: "采样时间",
              prop: "collectTime5",
            },
            {
              id: 53,
              label: "采样单位",
              prop: "covidCollectPoint5",
            },
            {
              label: "核酸结果",
              id: 54,
              prop: "labResult5",
            },
            {
              label: "检测单位",
              id: 55,
              prop: "labOrg5",
            },
            {
              label: "检测日期",
              id: 56,
              prop: "reportTime5",
            },
          ],
        },
        {
          label: "第6次核酸",
          id: 57,
          prop: "collectTime6",

          children: [
            {
              label: "采样时间",
              id: 58,
              prop: "collectTime6",
            },
            {
              label: "采样单位",
              id: 59,
              prop: "covidCollectPoint6",
            },
            {
              label: "核酸结果",
              id: 60,
              prop: "labResult6",
            },
            {
              label: "检测单位",
              id: 61,
              prop: "labOrg6",
            },
            {
              id: 62,
              label: "检测日期",
              prop: "reportTime6",
            },
          ],
        },
        {
          label: "第7次核酸",
          id: 63,
          prop: "collectTime7",

          children: [
            {
              label: "采样时间",
              id: 64,
              prop: "collectTime7",
            },
            {
              label: "采样单位",
              id: 65,
              prop: "covidCollectPoint7",
            },
            {
              label: "核酸结果",
              id: 66,
              prop: "labResult7",
            },
            {
              label: "检测单位",
              id: 67,
              prop: "labOrg7",
            },
            {
              label: "检测日期",
              id: 68,
              prop: "reportTime7",
            },
          ],
        },
      ],
      //3级
      dzprops: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3,
              }));
              resolve(nodes);
            });
          }
        },
      },
      //4级
      arearProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 1000);
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm,
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map((item) => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 4,
              }));
              resolve(nodes);
            });
          }
        },
      },
      isname: true,
    };
  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect,
    }), // 人群分类
  },
  filters: {
    crowdType(val) {
      for (let i in constants.TYPEPOP_SELECT) {
        if (val == constants.TYPEPOP_SELECT[i].value) {
          val = constants.TYPEPOP_SELECT[i].label;
        }
      }
      return val;
    },
  },
  created() {
    this.surveyResults = SURVEY_RESULT;
    this.getUserListByPeopleType();
    this.getOrgQueryList();
    this.caseList();
    this.getEventList();
    this.selectList();
  },
  mounted() {
    for (let i in this.treedata) {
      console.log(
        "🚀 ~ file: CaseCordsStatistics.vue ~ line 1121 ~ mounted ~ this.treedata",
        this.treedata
      );
      if (this.treedata[i].children) {
        for (let j of this.treedata[i].children) {
          console.log(
            "🚀 ~ file: CaseCordsStatistics.vue ~ line 1128 ~ mounted ~ this.treedata[i].children.j",
            j
          );

          this.ishh.push(j.prop);
        }
      }
      this.ishh.push(this.treedata[i].prop);
    }
  },

  methods: {
    //选择任务来源
    handleTaskSource() {
      let node = this.$refs.taskSourceRef.getCheckedNodes()[0];
      if (node) {
        let taskSource = node.pathLabels.toString();
        this.regitsterInfo.taskSource = taskSource;
      }
    },
    // 阳性人名信息
    async relationList(i) {
      console.log("i", i);
      let params = {
        relationName: i,
      };
      try {
        let { data } = await relationList({ params });
        console.log("relationList -> data", data);

        if (data.code == 200) {
          if (data.data.length == 0) {
            this.positiveCaseForm.relationName = i;
          } else {
            let hh = [];
            for (let i in data.data) {
              data.data[
                i
              ].zdm = `${data.data[i].relationName} ${data.data[i].relationCardNo} `;
              if (data.data[i].type == 0) {
                hh.push(data.data[i]);
              }
            }

            this.typepopSelect11 = hh;
          }
        }
      } catch (error) { }
    },
    // 密接人名信息
    async mqrelationList(i) {
      console.log("i", i);
      let params = {
        relationName: i,
      };

      try {
        let { data } = await relationList({ params });
        console.log("relationList -> data", data);

        if (data.code == 200) {
          if (data.data.length == 0) {
            this.closeContactForm.relationName = i;
          } else {
            let hh = [];
            for (let i in data.data) {
              data.data[
                i
              ].zdm = `${data.data[i].relationName} ${data.data[i].relationCardNo} `;
              if (data.data[i].type == 1) {
                hh.push(data.data[i]);
              }
            }

            this.typepopSelect11mj = hh;
          }
        }
      } catch (error) { }
    },
    //获取调查人员
    async getUserListByPeopleType() {
      const params = { peopleType: 4 };
      const { data } = await getUserListByPeopleTypeApi({ params });
      if (data.code == "200") {
        this.surveyUserNameSelect = data.data;
      }
    },
    // 获取组织架构列表
    async getOrgQueryList() {
      const params = { grades: 1, phFlag: 1 };
      const { data } = await getMedicalOrgList({ params });
      if (data.code == "200") {
        this.surveyObjectBelongOrgSelect = data.data;
      }
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      this.istableb02 = this.$refs.organizationData.getCheckedNodes(
        this.treedata
      );
      console.log(
        "🚀 ~ file: CaseCordsStatistics.vue ~ line 883 ~ handleCheckChange ~ istableb02",
        this.istableb02
      );
    },
    // 点击全选
    handleCheckAllChange(val) {
      if (this.checked) {
        console.log(
          " this.$refs.organizationData",
          this.$refs.organizationData
        );
        this.$refs.organizationData.setCheckedNodes(this.treedata);
      } else {
        this.$refs.organizationData.setCheckedKeys([]);
      }
    },
    // 点设置
    setup() {
      this.dialogVisible = true;
    },
    qd() {
      this.dialogVisible = false;
      this.ishh = [];
      this.istableb = this.$refs.organizationData.getCheckedNodes(
        this.treedata
      );

      for (let i in this.istableb) {
        this.ishh.push(this.istableb[i].prop);
      }
    },
    // 点击查询
    async caseList() {
      console.log("🚀 ~ 查询", this.regitsterInfo);
      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0] + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1] + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }
      let eventIds = [],
        placeIds = "",
        checkds = [],
        eventPlaceIds = []
      let arrrrrrrr = this.regitsterInfo.eventsite;

      for (let i in arrrrrrrr) {
        // arrrrrrrr[i]
        if (arrrrrrrr[i][1]) {
          eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
          eventPlaceIds.push(eventIds)
          checkds.push(arrrrrrrr[i][0]);
        } else if (arrrrrrrr[i][0] != null) {
          eventPlaceIds.push(arrrrrrrr[i][0])
        }

      }



      let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉


      if (this.regitsterInfo.surveyUserName == "") {
        this.regitsterInfo.surveyUserId = "";
      }
      if (this.regitsterInfo.taskSourceCode == "") {
        this.regitsterInfo.taskSource = "";
      }


      if (this.regitsterInfo.closelyNamezdm == "") {
        this.regitsterInfo.closelyCardNo = "";
        this.regitsterInfo.closelyName = "";

      }
      if (this.regitsterInfo.positiveNamezdm == "") {
        this.regitsterInfo.positiveCardNo = "";
        this.regitsterInfo.positiveName = "";
      }
      if (this.regitsterInfo.surveyObjectBelongOrg == "") {
        this.regitsterInfo.surveyObjectBelongOrgCode = "";
      }
      let params = {
        ...this.regitsterInfo,
        eventPlaceIds: newArr,
        placeIds: placeIds,

      };
      const { data } = await checkList(params);
      if (data.code == "200") {
        this.tableData = data.data;
      } else {
      }
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.caseList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.caseList();
    },
    //获取事件列表
    async getEventList(val) {
      const params = {};
      const { data } = await getEventListApi({ params });
      if (data.code == "200") {
        this.eventNameSelect = data.data;
      }
    },
    //隔离点下拉框
    async selectList() {
      const params = {};
      const { data } = await isolationpointList({ params });
      if (data.code == "200") {
        console.log(
          "🚀 ~ file: CaseCordsStatistics.vue ~ line 955 ~ selectList ~ data",
          data
        );
        // this.eventNameSelect = data.data;
        this.gldoptions = data.data;
      }
    },
    //选择调查人员
    handleSurveyUserName(item) {
      this.regitsterInfo.surveyUserName = item.name;
      this.regitsterInfo.surveyUserId = item.id;
    },
    // 导出
    escalateExport() {
      if (this.ldtime && this.ldtime.length != 0) {
        this.regitsterInfo.startTime = this.ldtime[0] + " 00:00:00";
        this.regitsterInfo.endTime = this.ldtime[1] + " 23:59:59";
      } else {
        this.regitsterInfo.startTime = "";
        this.regitsterInfo.endTime = "";
      }
      let eventIds = [],
        placeIds = "",
        checkds = [],
        eventPlaceIds = []
      let arrrrrrrr = this.regitsterInfo.eventsite;

      for (let i in arrrrrrrr) {
        // arrrrrrrr[i]
        if (arrrrrrrr[i][1]) {
          eventIds = `${arrrrrrrr[i][0]},${arrrrrrrr[i][1]}`
          eventPlaceIds.push(eventIds)
          checkds.push(arrrrrrrr[i][0]);
        } else if (arrrrrrrr[i][0] != null) {
          eventPlaceIds.push(arrrrrrrr[i][0])
        }

      }



      let newArr = eventPlaceIds.filter((item) => !checkds.some((ele) => ele === item)); //对比两个数组，把有相同的删掉

      if (this.regitsterInfo.surveyUserName == "") {
        this.regitsterInfo.surveyUserId = "";
      }
      if (this.regitsterInfo.taskSourceCode == "") {
        this.regitsterInfo.taskSource = "";
      }


      if (this.regitsterInfo.closelyNamezdm == "") {
        this.regitsterInfo.closelyCardNo = "";
        this.regitsterInfo.closelyName = "";

      }
      if (this.regitsterInfo.positiveNamezdm == "") {
        this.regitsterInfo.positiveCardNo = "";
        this.regitsterInfo.positiveName = "";
      }
      if (this.regitsterInfo.surveyObjectBelongOrg == "") {
        this.regitsterInfo.surveyObjectBelongOrgCode = "";
      }

      let params = Object.assign(
        {
          ...this.regitsterInfo,
          eventPlaceIds: newArr,
          placeIds: placeIds,
          hospitalId: this.userdoctor.hospitalId,
          orgCode: this.userdoctor.orgCode,
        }

        // this.tableList
      );
      // if (this.time && this.time.length == 2) {
      //   params.startTime = this.time[0] + " 00:00:00";
      //   params.endTime = this.time[1] + " 23:59:59";
      // }
      messageBox(
        () => {
          this.fullscreenLoading = true;
          exportList(params).then((res) => {
            console.log(11111111111, res);
            this.fullscreenLoading = false;
            importFile(res.data, "疫情排查检测管控");
          });
        },
        "warning",
        "是否确认导出 疫情排查检测管控 数据？"
      );
    },
    //选择所属社区
    handelSurveyObjectBelongOrg(value) {
      this.regitsterInfo.surveyObjectBelongOrg = value.orgCode;
      this.regitsterInfo.surveyObjectBelongOrgCode = value.orgCode;
    },
    // 回显
    echo(v) {
      console.log("echo -> v", v);
      this.regitsterInfo.closelyCardNo = v.relationCardNo;
      this.regitsterInfo.closelyName = v.relationName;
      this.regitsterInfo.closelyNamezdm = v.zdm;
    },
    yxecho(v) {
      console.log("echo -> v", v);
      this.regitsterInfo.positiveCardNo = v.relationCardNo;
      this.regitsterInfo.positiveName = v.relationName;
      this.regitsterInfo.positiveNamezdm = v.zdm;
    },
    // 查看更多
    handleClick(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },
    // 同步检测
    synchronousDetection() {
      pullCovidResult().then(res => {
        this.$message.success('数据已同步');
      })

    }
  },
};
</script>
<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.top {
  width: 100%;
}

.bottom {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top_01 {
  display: flex;
  justify-content: space-between;
}

.top_011 {
  display: flex;
}

.top_01_div {
  display: flex;
  align-items: center;
}

.top_01_div span {
  width: 110px;
  text-align: right;
  margin-right: 10px;
}

.top_02 {
  margin-top: 20px;
}

.sst {
  height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.dialog_top {
  background-color: #f5f5f5;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.dialog_bottom {
  background-color: #f5f5f5;
  height: 40px;
  display: flex;
  justify-content: end;
  padding: 0 20px;
  align-items: center;
}

/deep/.el-dialog__body {
  padding: 0;
}

.el-pagination {
  display: flex;
  justify-content: flex-end;
}
</style>